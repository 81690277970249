<template>
    <Layout>
        <div class="row mb-3 align-items-center">
            <div class="col">
                <PageHeader title="Pending Payments" />
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="mb-3">
                    <div class="row gx-2">
                        <!-- <div class="col"><h3 class="my-1 font-size-18 text-dark-cstm">The Wreckage of Attraction</h3></div> -->
                        <div class="col text-end">
                            <div class="topBarCstmTable searchBarStyle position-relative">
                                <input type="text" v-model="searchValueTable" class="form-control"
                                    placeholder="Search..." />
                                <span class="iconSearch bx bx-search-alt"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <EasyDataTable :headers="headers" :items="tableItems" :search-value="searchValueTable" border-cell
                    theme-color="#3aafa9" :rows-per-page="25" buttons-pagination table-class-name="table-custom-style"
                    show-index>

                    <template #item-title="{ email, title, packages }">
                        <template v-if="email == null">{{ title }}</template>
                        <template v-else>
                            <template v-for="packageData in packages" :key="packageData.id">
                                <span v-if="packages.length == 1">{{ packageData.title }}</span>
                                <span v-else>{{ packageData.title }}, </span>
                            </template>
                        </template>
                    </template>
                    <template #item-clientName="{ user_id }">
                        <router-link :to="`/single-client/${encode(user_id.id)}`" class="text-theme ">{{
                            user_id.firstName }} {{ user_id.lastName }}</router-link>
                    </template>
                    <template #item-amount="{ amount, email, packages, extraService, wordCount, story_id }">
                        <template v-if="email == null">
                            <p
                                v-if="extraService && extraService.title !== '5 Journals' && extraService.title !== '15 Journals'">
                                ${{ (((parseInt(wordCount)) / 1000 *
                                    parseFloat(extraService.amount)).toFixed(2)) }}</p>
                            <p v-else>${{ parseFloat(amount).toFixed(2) }}</p>
                        </template>
                        <template v-else>
                            <template v-for="packageData in packages" :key="packageData.id">
                                <!-- <span v-if="packages.length == 1">{{ packageData.amount }}</span> -->
                                <!-- <span>{{ packageData.amount }}</span> -->
                            </template>
                            ${{ sumAmount(packages, story_id) }}
                        </template>
                    </template>
                    <template #item-story_id="{ story_id }">
                        <router-link :to="`/single-story/${encode(story_id.id)}`" class="text-theme ">{{
                            story_id.storyTitle }}</router-link>
                    </template>
                    <template #item-status="{ status }">
                        <span>{{ status }}</span>
                    </template>
                    <!-- <template #item-action="{ id }">
                        <button class="btn btn-success" type="button" @click="approveSchedulePayment(id)">Done</button>
                    </template> -->
                    <template #item-action="{ email, id }">
                        <template v-if="email == null">
                            <button class="btn btn-success" type="button" @click="approveExtraPayment(id)">Paid</button>
                        </template>
                        <template v-else>
                            <button class="btn btn-success" type="button"
                                @click="approveSchedulePayment(id)">Paid</button>
                        </template>
                        <button class="btn btn-danger ml-2" style="margin-left: 2px;" type="button"
                            @click="deletePendingPayment(id, email)">Delete</button>
                    </template>
                </EasyDataTable>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../../router/layouts/main";
import PageHeader from "@/components/admin/header/page-header";
import axios from "axios";
import CryptoJS from 'crypto-js';
import EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
export default {
    page: {
        title: "Add Extra Services",
        meta: [
            {
                name: "description",
                content: "description",
            },
        ],
    },
    data() {
        return {
            title: "Add Extra Services",
            url: process.env.VUE_APP_URL,
            submitted: false,
            searchValueTable: '',
            show: [],
            tableItems: [],
            headers: [
                { text: "Client Name", value: "clientName" },
                { text: "Story", value: "story_id" },
                { text: "Amount", value: "amount" },
                { text: "Service", value: "title" },
                { text: "Status", value: "status" },
                { text: "", value: "action" },
            ],

        };

    },
    components: {
        PageHeader,
        Layout,
        EasyDataTable,
    },
    mounted() {
        this.getPendingPayments()
    },
    activated() {
        this.getPendingPayments();
    },

    methods: {
        sumAmount(packages, story) {
            let totalAmount = 0;
            let submission = null;
            let fiction = null;
            let editing = null;
            for (const packageData of packages) {
                if (packageData.type == 'submission') {
                    submission = packageData;
                } else if (packageData.type == 'flash') {
                    fiction = packageData;
                } else if (packageData.type == 'editing') {
                    editing = packageData;
                }
            }

            if (submission) {
                totalAmount += parseFloat(submission.amount);
            }

            if (fiction) {
                totalAmount += parseFloat(fiction.amount);
            }

            if (editing && editing.title == 'Consulting: 1 hour') {
                totalAmount += parseFloat(editing.amount);
            }

            if ((submission?.title == '20 Journals' || fiction?.title == '20 Journals (3 Stories)' || submission?.title == 'Journals List Only') && (editing && editing.title !== 'Consulting: 1 hour')) {
                totalAmount -= (parseFloat(((Number(story.wordCount) / 1000) * editing.amount)) * 10) / 100;
            } else if ((submission?.title == '20 Journals' || fiction?.title == '20 Journals (3 Stories)' || submission?.title == 'Journals List Only') && (editing && editing.title == 'Consulting: 1 hour')) {
                totalAmount -= (parseFloat(editing.amount) * 10) / 100;
            }

            if (story.wordCount > 3500 && !editing) {
                totalAmount += ((Number(story.wordCount) - 3500) / 1000) * 35;
            } else if (editing?.title == 'Line Editing' && submission?.title == 'Journals List Only') {
                totalAmount += (Number(story.wordCount) / 1000) * 85;
            } else if (editing?.title == 'Line Editing' && submission?.title != 'Journals List Only') {
                totalAmount += (Number(story.wordCount) / 1000) * 85;
            } else if (editing?.title == 'Copy Editing' && submission?.title != 'Journals List Only') {
                totalAmount += (Number(story.wordCount) / 1000) * 60;
            } else if (editing?.title == 'Copy Editing' && submission?.title == 'Journals List Only') {
                totalAmount += (Number(story.wordCount) / 1000) * 60;
            } else if (editing?.title == 'Read Only') {
                totalAmount += (Number(story.wordCount) / 1000) * 35;
            }
            return totalAmount.toFixed(2);
        },
        // sumAmount(packages , story) {
        //     let totalAmount = 0;
        //     for (const packageData of packages) {
        //         totalAmount += parseInt(packageData.amount);
        //     }
        //     return totalAmount;
        // },

        getPendingPayments() {
            axios
                .get(this.url + "api/getPendingPayments", {})
                .then((res) => {
                    this.tableItems = res.data;
                });
        },
        approveExtraPayment(id) {
            axios.post(this.url + 'api/approveExtraPayment', { id: id }).then(() => {
                this.$store.state.isAccepted = true
                this.$store.state.notificationData = 'Payment Approved Successfully'
                this.getPendingPayments();
                setTimeout(() => {
                    this.$store.state.isAccepted = false
                    this.$store.state.notificationData = null
                }, 2500)
            })
        },
        approveSchedulePayment(id) {
            axios.post(this.url + 'api/approveSchedulePayment', { id: id }).then(() => {
                this.$store.state.isAccepted = true
                this.$store.state.notificationData = 'Payment Approved Successfully'
                this.getPendingPayments();
                setTimeout(() => {
                    this.$store.state.isAccepted = false
                    this.$store.state.notificationData = null
                }, 2500)
            })
        },
        deletePendingPayment(id, email) {
            if (confirm('Are you sure you want to delete this payment?')) {
                axios.post(this.url + 'api/deletePendingPayment', { id: id, email: email }).then(() => {
                    this.$store.state.isDeclined = true
                    this.$store.state.notificationData = 'Payment Deleted Successfully'
                    this.getPendingPayments();
                    setTimeout(() => {
                        this.$store.state.isDeclined = false
                        this.$store.state.notificationData = null
                    }, 2500)
                })
            }
        },
        encode(id) {
            return encodeURIComponent(CryptoJS.AES.encrypt(String(id), "Secret Passphrase"));
        }
    },
};
</script>